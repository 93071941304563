<template>
    <div>
        <!-- 签到页面 -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            id: '',
        }
    },
    mounted() {
        // 拿到传过来的活动id
        this.id = this.$globalData.result
        this.signActivity()
    },
    methods: {
        signActivity() {
            this.$http({
                url: this.$http.adornUrl('/wxapp/party/activity/sign/sign'),
                method: 'post',
                params: this.$http.adornParams({
                    userId: this.$globalData.userInfo.userId,
                    activityId: this.id
                })
            }).then(({data}) => {
                if(data && data.code === 0) {
                    this.$dialog.alert({
                        message: '签到成功',
                    }).then(() => {
                        this.$router.go(-1)
                    });
                }else {
                    this.$toast.fail(data.msg)
                }
            })
        }
    }
}
</script>

<style>

</style>